import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"


import Layout from "../components/layout"
import ProjectHero from "../components/ProjectHero";
import "./Article.css"
import ProjectCard from "../components/ProjectCard";



export default props => {
  return (
    <Layout>
      <ProjectHero
        title="Start Booking Experience Redesign"
        intro="Start is a Beijing-based P2P car-sharing platform (Airbnb for cars 🚗) with over 3,000,000 users. During my internship at Start, I redesigned the car booking user flow, which resulted in an 18% increase on page CTR one week after launch."
        client="Start (Car-Sharing Service)"
        team="Solo work, 4 weeks in 2017"
        myrole="User research, Competitive study, Wire-framing, Interaction design, User testing"
        />

    <div className="FullImage">
        <Img fluid={props.data.mockup.childImageSharp.fluid} />
    </div>

    <div className="ArticleSection">
      <h1 style={{color:"#FC434E"}}>Challenge —―</h1>
      <div className="ArticleBody">
        <h2>How might we streamline the car booking user flow?</h2>
        <p>START version 5.0 aimed at encouraging users to explore our car collections even when they were not actively trying to make a booking (i.e. “car shopping”.) However, the booking interaction for "shopping users" is very complicated -- that may prevent them from placing their order. <em>How might we make car booking easier for “shopping” users?</em></p>
      </div>
    </div>

    <div className="BackgroundWrap" color="white">
            <div className="RegImage"><Img fluid={props.data.vision.childImageSharp.fluid} /></div>
    </div>

    <div className="ArticleSection">
      <div className="ArticleBody">
        <h2>What makes car booking so complicated?</h2>
        <p>"Shopping users" did not need to choose a rental time period to start looking. While that made it easy for people to start browsing cars, when they were actually placing an order, they'd need to consider a lot of things all at once. To begin with, I listed information needed to display on the booking screen.
        <li>
          <ul>Date: available / booked / not on share; selected dates</ul>
          <ul>Time: available / booked; selected time</ul>
          <ul>Price (vary with date)</ul>
        </li>
        </p>
      </div>
    </div>

    <div className="ArticleSection">
      <h1 style={{color:"#FC434E"}}>Ideate —―</h1>
      <div className="ArticleBody">
        <h2>Paper prototyping</h2>
        <p>To begin with, I studied competitive applications and created paper prototypes to quickly test out design possibilities (date + time paring).</p>
      </div>
    </div>

    <div className="BackgroundWrap" color="white">
            <div className="RegImage"><Img fluid={props.data.paperPrototype.childImageSharp.fluid} /></div>
    </div>

    <div className="ArticleSection">
      <div className="ArticleBody">
        <p>I identified two most promising directions (highlighted above) and created two digital prototypes.</p>
      </div>
    </div>

    <div className="BackgroundWrap" color="white">
            <div className="RegImage"><Img fluid={props.data.midFi.childImageSharp.fluid} /></div>
    </div>

    <div className="ArticleSection">
      <h1 style={{color:"#FC434E"}}>Test —―</h1>
      <div className="ArticleBody">
        <h2>Which interaction pattern works better for users?</h2>
        <p>At that point, I was not sure which direction to go. From a designer’s point of view, I preferred the “stacked” pattern for its simplicity. However, I was not sure if users can understand how the time sliders work. I decided to conduct comparative usability tests to learn how users feel.</p>
      </div>
    </div>

    <div className="BackgroundWrap" color="white">
        <div className="RegImage"><Img fluid={props.data.prompt.childImageSharp.fluid} /></div>
    </div>  

    <div className="BackgroundWrap" color="white">
        <div className="RegImage"><Img fluid={props.data.testing.childImageSharp.fluid} /></div>
    </div>  


    <div className="ArticleSection">
      <div className="ArticleBody">
        <p>Key findings:
        <br />- All 16 participants found the time sliders in Ver 1 easy to understand
        <br />- Average time on task for Ver 1 was shorter
        <br />- Users had no strong preference between the two designs
        <br />
        </p>
      </div>
    </div>

    <div className="BackgroundWrap" color="white">
        <div className="RegImage"><Img fluid={props.data.results.childImageSharp.fluid} /></div>
    </div>  

    <div className="ArticleSection">
      <div className="ArticleBody">
        <h2>Is it easy to make precise selections with the "time slider"?</h2>
        <p>I shared the testing findings with the team. While people were convinced that version 1 ("stacked") would work better, our developers questioned the usability of the time slider. To test if there'd be enough screen real-estate to support precise selection on the slider, I created a realistic interactive prototype with Framer, and tried the slider on various screen sizes.</p>
      </div>
    </div>    

    <div className="BackgroundWrap" color="white">
        <div className="RegImage"><Img fluid={props.data.framer.childImageSharp.fluid} /></div>
    </div> 

    <div className="BackgroundWrap" color="white">
        <div className="RegImage"><Img fluid={props.data.screen.childImageSharp.fluid} /></div>
    </div> 

    <div className="ArticleSection">
      <div className="ArticleBody">
        <p>Synthesizing the findings, I decided to go with version 1 and shared the prototype with our engineers and the rest of the product team.</p>
      </div>
    </div>  


    <div className="ArticleSection">
      <h1 style={{color:"#FC434E"}}>Refine —―</h1>
      <div className="ArticleBody">
        <h2>Crafting interaction details</h2>
        <p>Major design decisions being made, I moved forward to fix the usability issues identified in the usability testing, and polish the interaction to further streamline the user flow.</p>
      </div>
    </div>

    <div className="BackgroundWrap" color="black">
        <div className="FullImage"><img src={require('../images/start/pop-up.gif')} /></div>
    </div> 
    <div className="BackgroundWrap" color="black">
        <div className="FullImage"><img src={require('../images/start/time-slider-autofill.gif')} /></div>
    </div> 
    <div className="BackgroundWrap" color="black">
        <div className="FullImage"><img src={require('../images/start/error.gif')} /></div>
    </div> 

    <div className="ArticleSection">
      <h1 style={{color:"#FC434E"}}>Deliver —―</h1>
      <div className="ArticleBody">
        <h2>Project requirement document</h2>
        <p>Finally, I wrote up a project requirement document to ship the design. During this process, I asked our PMs and QAs for feedback and identified and designed for many edge cases and error states.</p>
      </div>
    </div>

    <div className="BackgroundWrap" color="white">
        <div className="RegImage"><Img fluid={props.data.prd.childImageSharp.fluid} /></div>
    </div> 

    <div className="ArticleSection">
      <div className="ArticleBody">
        <h2>Project outcome</h2>
        <ul>
          <li>18% increase on booking screen click-through-rate one week after launch</li>
          <li>Average time on task decreased by 25%</li>
        </ul>
      </div>
    </div>

    <div className="VideoWrap" color="grey">
            <iframe src="https://player.vimeo.com/video/251081962?title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>

    <div className="ArticleSection">
      <div className="Next">
        <h6 style={{color:"#8E8E8E"}}>Next up</h6>
        <ProjectCard 
            link="/fastback-studios/"
            title="Fastback Studios"
            tagline="Help a recording studio connect with professional musicians"
            tag="Website • Course Project"
            // image={require('../images/test.png')}
            background={require('../images/cover/fastback.png')}
            />
      </div>
    </div>    


  </Layout>

  )
}

export const query = graphql`
  query {
    mockup: file(relativePath: { eq: "start/mock-up.jpg" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    vision: file(relativePath: { eq: "start/vision.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }


    paperPrototype: file(relativePath: { eq: "start/paper-prototype.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    
    prompt: file(relativePath: { eq: "start/prompt.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }   

    testing: file(relativePath: { eq: "start/user-test.jpg" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }   

    results: file(relativePath: { eq: "start/results.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }   


    framer: file(relativePath: { eq: "start/framer.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }   

    screen: file(relativePath: { eq: "start/screen-size.jpg" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }  


    prd: file(relativePath: { eq: "start/prd.jpg" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }  


    midFi: file(relativePath: { eq: "start/mid-fi.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }  




  }
`
